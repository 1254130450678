<template>
  <!-- begin:: Footer -->
<!--  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">-->
<!--      <div class="text-dark text-center">-->
<!--        <span class="text-muted font-weight-bold mr-2">-->
<!--          2022 &nbsp;&copy;&nbsp;Ustaadh Admin App-->
<!--        </span>-->
<!--      </div>-->
<!--  </div>-->
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
