<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
    <div class="brand-logo" style="height: 70px">
      <h3 class="mb-0" style="color: #fff">Admin Panel</h3>
<!--        <img src="/media/logos/ustaadh-logo.png" class="custom-brand" />-->
    </div>
    <div class="brand-tools" v-if="allowMinimize">
      <button
        class="brand-toggle btn btn-sm px-0"
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
      >
        <span class="svg-icon svg-icon-xl">
<!--          <v-icon class="svg-icon" dark>fas fa-angle-left</v-icon>-->
          <inline-svg
            class="svg-icon"
            src="media/svg/icons/Navigation/Angle-double-right.svg"
          />
        </span>
      </button>
    </div>
  </div>
  <!-- end:: Aside -->
</template>
<style lang="scss" scoped>
#Layer_1{width: 100%}
//.brand{background-color: #d6d7d9}
.cls-1, .cls-6 {
  fill: #efefef;
  stroke-width: 5px;
}

.cls-1, .cls-5, .cls-6 {
  stroke-miterlimit: 10;
}

.cls-1 {
  stroke: url(#linear-gradient);
}

.cls-2 {
  font-size: 91.41px;
  fill: #111;
  font-family: Poppins-Regular, Poppins;
}

.cls-3 {
  font-size: 85.42px;
  font-family: Comfortaa-Bold, Comfortaa;
  font-weight: 700;
}

.cls-4 {
  letter-spacing: -0.03em;
}

.cls-5 {
  fill: none;
  stroke: #000;
  stroke-width: 4px;
}

.cls-6 {
  stroke: url(#linear-gradient-2);
}

.cls-7 {
  opacity: 0.5;
}
.aside-toggle {
  outline: none;
}
.custom-brand{
  width: 35%;
  margin: 0 auto;
}
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";

export default {
  name: "KTBrand",
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    }
  }
};
</script>
