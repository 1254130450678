<template>
  <div class="container">
    <div class="row login-w3ls">
      <div class="col-md-12">
        <div class="card-content p-2 text-center">
          <h4>Verification required!</h4>
          <p style="text-align: center" class="alert alert-success">
            A verification link was sent again to <b>{{ this.$store.state.auth.user.email }}</b> <br>
            Please click
            on that link to verify your email.
          </p>
          <p style="text-align: center"><b>Can't find any email?</b></p>
          <p style="text-align: center">Check your spam folder <br> or <br>
            <a style="text-decoration: underline"
               href="javascript:void(0)" @click="sendVE">Get verification link again</a>
          </p>
          <hr>
          <button class="btn btn-primary btn-bold text-center" @click="onLogout">
            Back to Login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {DO_LOGOUT, SEND_VERIFICATION_EMAIL} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  methods: {
    onLogout() {
      this.$store
          .dispatch(DO_LOGOUT)
    },
    sendVE(){
      this.$store
          .dispatch(SEND_VERIFICATION_EMAIL, {
            email: this.$store.state.auth.user.email
          })
          .then((data) => {
            Swal.fire('', data._metadata.message, 'success')
          });
    }
  },
}
</script>